define("discourse/plugins/discourse-newsletter-integration/discourse/initializers/setup", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-newsletter-integration-setup",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        const currentUser = api.getCurrentUser();
        if (!currentUser) {
          return;
        }
        api.addSaveableUserField("newsletter_integration_subscribe_global_newsletter");
        api.modifyClass("controller:preferences/emails", {
          pluginId: "discourse-newsletter-integration-emails-preference",
          init() {
            this._super(...arguments);
            this.saveAttrNames.push("newsletter_integration_subscribe_global_newsletter");
          }
        });
      });
    }
  };
});