define("discourse/plugins/discourse-newsletter-integration/discourse/templates/connectors/user-preferences-emails/subscribe-newsletter-section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showSubscribeSection}}
    <div class="control-group newsletter-integration-subscribe-section">
      <label class="control-label">{{i18n
          "discourse_newsletter_integration.preferences.section_head"
        }}</label>
      <PreferenceCheckbox
        @labelKey="discourse_newsletter_integration.preferences.checkbox_description"
        @checked={{@outletArgs.model.newsletter_integration_subscribe_global_newsletter}}
        class="subscribe-checkbox"
      />
    </div>
  {{/if}}
  */
  {
    "id": "/El8grnS",
    "block": "[[[41,[30,0,[\"showSubscribeSection\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group newsletter-integration-subscribe-section\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"discourse_newsletter_integration.preferences.section_head\"],null]],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"subscribe-checkbox\"]],[[\"@labelKey\",\"@checked\"],[\"discourse_newsletter_integration.preferences.checkbox_description\",[30,1,[\"model\",\"newsletter_integration_subscribe_global_newsletter\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-newsletter-integration/discourse/templates/connectors/user-preferences-emails/subscribe-newsletter-section.hbs",
    "isStrictMode": false
  });
});