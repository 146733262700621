define("discourse/plugins/discourse-newsletter-integration/discourse/connectors/top-notices/newsletter-banner", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _tracking, _object, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsletterBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "disableControls", [_tracking.tracked], function () {
      return false;
    }))();
    #disableControls = (() => (dt7948.i(this, "disableControls"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "dismissed", [_tracking.tracked], function () {
      return false;
    }))();
    #dismissed = (() => (dt7948.i(this, "dismissed"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "subscribed", [_tracking.tracked], function () {
      return false;
    }))();
    #subscribed = (() => (dt7948.i(this, "subscribed"), void 0))();
    get showBanner() {
      return this.site.newsletter_integration_plugin_configured && this.currentUser?.show_newsletter_subscription_banner && !this.dismissed;
    }
    async subscribe() {
      this.disableControls = true;
      try {
        await (0, _ajax.ajax)("/newsletter-integration/subscriptions", {
          type: "POST"
        });
        this.subscribed = true;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
      this.disableControls = false;
    }
    static #_6 = (() => dt7948.n(this.prototype, "subscribe", [_object.action]))();
    async dismiss() {
      this.dismissed = true;
      if (!this.subscribed) {
        try {
          await (0, _ajax.ajax)("/newsletter-integration/subscriptions", {
            type: "DELETE"
          });
        } catch (e) {
          this.dismissed = false;
          (0, _ajaxError.popupAjaxError)(e);
        }
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "dismiss", [_object.action]))();
  }
  _exports.default = NewsletterBanner;
});